import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 600px;
  width: 100%;

  margin: 0 auto;

  @media (max-width: 767px) {
    order: ${props => (props.order ? props.order : "")};
  }
`;

const TextContainer = ({ children, order }) => (
  <Container order={order}>{children}</Container>
);
export default TextContainer;
