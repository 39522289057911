import React from "react";
import styled from "styled-components";

const ImageContainer = styled.div`
  position: relative;
  // overflow: hidden;

  width: 100%;
  height: 100%;

  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "transparent"};

  background-image: url(${props =>
    props.backgroundImage ? props.backgroundImage : "none"});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  max-width: 1200px;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 66.667%;
  }
  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const Video = styled.video`
  width: auto;
  height: auto;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  object-fit: cover;
  object-position: center;

  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  z-index: 0;
`;

const Image = styled.img`
  position: relative;

  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "100%")};

  max-width: 1200px;

  object-fit: ${props =>
    props.objectFit ? props.objectFit : "contain"} !important;
  padding: ${props => (props.padding ? props.padding : "0")} !important;

  z-index: 100;
`;

const ThreeTwo = ({
  image,
  alt,
  lazy,
  size,
  objectFit,
  backgroundColor,
  backgroundImage,
  padding,
  video,
  width,
  height,
}) => {
  return (
    <ImageContainer
      className="image-container"
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      width={width}
      height={height}
    >
      <div className="content">
        {image && (
          <Image
            width={width}
            height={height}
            objectFit={objectFit}
            // srcSet={`${image}&h=${size}&crop=entropy&q=80 1x,
            // ${image}&h=${size}&crop=entropy&dpr=2&q=80 2x,
            // ${image}&h=${size}&crop=entropy&dpr=3&q=80 3x`}
            // src={`${image}&h=${size}&crop=entropy&q=80`}
            src={image}
            loading={lazy === true ? "lazy" : "auto"}
            alt={alt}
            padding={padding}
          />
        )}

        {video && (
          <Video loop autoPlay muted playsInline preload="auto">
            <source type="video/mp4" src={video} />
          </Video>
        )}
      </div>
    </ImageContainer>
  );
};

export default ThreeTwo;
