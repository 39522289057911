import React, { useEffect } from "react";
import styled from "styled-components";

// Styles
import { linkColor } from "../styles/colors";

// Components
import TextContainer from "../containers/text-container";
import Line from "../misc/line";
import Title from "../text/title";
import TextBox from "../text/text-box";
import ThreeTwo from "../images/three-two";
import ImageCaption from "../text/image-caption";

const ContextualImageWrapper = styled.div`
  width: 100%;

  max-width: 900px;
  margin: ${props => props.margin};

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    margin: 0 auto 20px auto;
  }
`;

const TextLink = styled.div`
  & p {
    margin: 0;
  }
  & p::before {
    content: "→ ";
    display: inline;
    font-family: "Starling Arrow";
  }

  & a:hover {
    color: ${linkColor};
  }
`;

const ResearchPage = ({ data, setPageTitle, title, tags, client }) => {
  useEffect(() => {
    setPageTitle(title);
  }, []);

  const contextualData = data.body1.map((data, index) => {
    if (data.slice_type === "title") {
      return (
        <TextContainer key={`contextual_title_${index}`}>
          <Title
            text={data.primary.contextual_title}
            margin={`30px 0`}
            showDesktop={`block`}
            showMobile={`block`}
          />
        </TextContainer>
      );
    }

    if (data.slice_type === "text") {
      return (
        <TextContainer key={`contextual_text_${index}`}>
          <TextBox
            text={data.primary.text}
            margin={`30px 0`}
            mobileMargin={`30px 0`}
          />
          {data.items.map((link, index) => (
            <TextLink
              key={`link_${index}`}
              dangerouslySetInnerHTML={{ __html: link.link.html }}
            />
          ))}
        </TextContainer>
      );
    }

    if (data.slice_type === "footnotes") {
      return (
        <>
          <Line
            mobileWidth={`100vw`}
            margin={`30px -20px 30px -20px`}
            mobileMargin={`0 -10px 10px -10px`}
          />
          <TextContainer key={`contextual_footnotes_${index}`}>
            <TextBox text={data.primary.footnotes} margin={`0`} />
          </TextContainer>
        </>
      );
    }

    if (data.slice_type === "image") {
      return (
        <ContextualImageWrapper
          key={`contextual_image_${index}`}
          margin={index === 0 ? `30px auto 60px auto` : `60px auto`}
        >
          <ThreeTwo
            image={data.primary.image.url}
            objectFit={
              data.primary.image.dimensions.width >
              data.primary.image.dimensions.height
                ? `cover`
                : `contain`
            }
          />
          <ImageCaption text={data.primary.image.alt} />
        </ContextualImageWrapper>
      );
    }
  });

  return (
    <>
      <Title
        text={title}
        margin={`30px 0`}
        showDesktop={`none`}
        showMobile={`block`}
        order={1}
      />
      {contextualData}
    </>
  );
};

export default ResearchPage;
