import React from "react";
import styled from "styled-components";

//Styles
import { borderColor } from "../styles/colors";

const Hr = styled.hr`
  display: ${props => (props.showDesktop ? props.showDesktop : "block")};

  margin: ${props => (props.margin ? props.margin : "0 0 27px 0")};
  padding: 0;

  border: 0;
  border-style: solid;
  border-bottom: 1px solid ${borderColor};

  // width: 100%;

  @media (max-width: 767px) {
    display: ${props => (props.showMobile ? props.showMobile : "block")};

    margin: ${props =>
      props.mobileMargin ? props.mobileMargin : "0 -10px 25px -10px"};

    width: ${props => (props.mobileWidth ? props.mobileWidth : "auto")};

    order: ${props => (props.order ? props.order : "")};
  }
`;

const Line = ({
  margin,
  mobileMargin,
  showDesktop,
  showMobile,
  mobileWidth,
  order,
}) => (
  <Hr
    margin={margin}
    mobileMargin={mobileMargin}
    showDesktop={showDesktop}
    showMobile={showMobile}
    mobileWidth={mobileWidth}
    order={order}
  />
);
export default Line;
