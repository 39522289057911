import React, { useMemo } from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import { mergePrismicPreviewData } from "gatsby-source-prismic";

// Styles
import { linkColor } from "../components/styles/colors";

// Components
import { PageTitleConsumer } from "../components/context/page-title-context";
import ResearchPage from "../components/research/research-page";
import Line from "../components/misc/line";

const Page = styled.div`
  padding: 66px 20px 0 20px;

  @media (max-width: 1160px) {
    padding: 65px 20px 0 20px;
  }

  @media (max-width: 767px) {
    padding: 36px 10px 7px 10px;
  }
`;

const NextPrevProjects = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;

  & div {
    padding: 12px 0 9px 0;
  }

  & a {
    width: 50%;

    line-height: 1;

    text-align: center;

    &:nth-of-type(3) {
      display: none;
    }

    &:first-of-type {
      border-right: 1px solid #000;
    }

    &:hover {
      color: ${linkColor};
    }

    & h3 {
      font-size: 15px;
    }

    @media (max-width: 767px) {
      &:first-of-type,
      &:nth-of-type(2) {
        display: none;
      }

      &:nth-of-type(3) {
        width: auto;
        display: block;
        text-align: left;
      }
    }

    @media (max-width: 500px) {
      width: 100%;

      line-height: 21px;
    }
  }

  @media (max-width: 767px) {
    display: none;
    order: 12;
  }
`;

const MobileNextPrevProjects = styled.div`
  display: none;

  width: 100%;

  & a {
    width: 50%;

    line-height: 35px;

    text-align: center;

    & h3 {
      font-size: 15px;
    }

    @media (max-width: 767px) {
      width: auto;
      display: block;
      text-align: left;
    }

    @media (max-width: 500px) {
      width: 100%;

      line-height: 21px;
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    order: 12;
  }
`;

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== "undefined";

const Research = ({ data: staticData }) => {
  const data = useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  const seoPageTitle = data.prismicResearch.data.title.text
    ? `${data.prismicResearch.data.title.text} – Wolfe Hall`
    : "Wolfe Hall";

  const findTextDescription = data.prismicResearch.data.body1
    .filter(content => content !== null && content !== undefined)
    .filter(content => content.slice_type === "text");

  const seoDescription =
    findTextDescription.length >= 1 &&
    `${findTextDescription[0].primary.text.text.substring(0, 99)}...`;

  const findSeoImage = data.prismicResearch.data.body1
    .filter(content => content !== null && content !== undefined)
    .filter(content => content.slice_type === "image");

  const seoImage =
    findSeoImage[0].primary.image.url && `${findSeoImage[0].primary.image.url}`;

  const nextLink = data.prismicResearch.data.next_link;
  const previousLink = data.prismicResearch.data.previous_link;

  return (
    <PageTitleConsumer>
      {({ setPageTitle }) => (
        <Page>
          <Helmet
            title={seoPageTitle}
            meta={[
              {
                name: "description",
                content: `${seoDescription}`,
              },
              {
                name: "og:image",
                content: `${seoImage}`,
              },
              {
                name: "og:image:secure_url",
                content: `${seoImage}`,
              },
              {
                name: "og:description",
                content: `${seoDescription}`,
              },
              {
                name: "twitter:description",
                content: `${seoDescription}`,
              },
              {
                name: "twitter:image",
                content: `${seoImage}`,
              },
            ]}
          />
          <ResearchPage
            data={data.prismicResearch.data}
            setPageTitle={setPageTitle}
            title={data.prismicResearch.data.title}
            tags={data.prismicResearch.tags}
            client={data.prismicResearch.data.client}
          />

          <Line
            mobileWidth={`100vw`}
            margin={`60px -20px 0 -20px`}
            mobileMargin={`0 -10px 10px -10px`}
          />
          <NextPrevProjects>
            {previousLink.document !== null && (
              <Link to={previousLink.document.uid}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: previousLink.document.data.title.html,
                  }}
                />
              </Link>
            )}
            {nextLink.document !== null && (
              <Link to={nextLink.document.uid}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: nextLink.document.data.title.html,
                  }}
                />
              </Link>
            )}
          </NextPrevProjects>

          <MobileNextPrevProjects>
            <Link to={`/projects`}>
              <strong>←</strong> Back to Research
            </Link>
          </MobileNextPrevProjects>
        </Page>
      )}
    </PageTitleConsumer>
  );
};

export const query = graphql`
  query Research($uid: String!) {
    prismicResearch(uid: { eq: $uid }) {
      prismicId
      data {
        title {
          html
          text
        }
        next_link {
          document {
            ... on PrismicResearch {
              uid
              url
              prismicId
              data {
                title {
                  html
                }
              }
              type
            }
            ... on PrismicResearch {
              uid
              url
              prismicId
              data {
                title {
                  html
                }
              }
              type
            }
          }
        }
        previous_link {
          document {
            ... on PrismicResearch {
              uid
              url
              type
              prismicId
              data {
                title {
                  html
                }
              }
            }
            ... on PrismicResearch {
              uid
              url
              type
              prismicId
              data {
                title {
                  html
                }
              }
            }
          }
        }
        body1 {
          ... on PrismicResearchBody1Title {
            slice_type
            id
            primary {
              contextual_title {
                html
              }
            }
          }
          ... on PrismicResearchBody1Text {
            id
            slice_type
            primary {
              text {
                html
                text
              }
            }
            items {
              link {
                html
              }
            }
          }
          ... on PrismicResearchBody1Image {
            id
            slice_type
            primary {
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicResearchBody1Footnotes {
            id
            slice_type
            primary {
              footnotes {
                html
              }
            }
          }
        }
      }
    }
  }
`;

export default Research;
