import React from "react";
import styled from "styled-components";

// Styles
import { linkColor } from "../styles/colors";

const Text = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth};

  margin: ${props => (props.margin ? props.margin : "")};

  & p {
    font-size: ${props => (props.fontSize ? props.fontSize : `19px`)};
    line-height: ${props => (props.lineHeight ? props.lineHeight : `25px`)};

    letter-spacing: 0.05px;

    @media (max-width: 767px) {
      font-size: ${props =>
        props.mobileFontSize ? props.mobileFontSize : `16px`};
      line-height: ${props =>
        props.mobileLineHeight ? props.mobileLineHeight : `21px`};
    }
  }

  & p:first-of-type {
    margin-top: 0;
  }

  & a:hover {
    color: ${linkColor};
  }

  & ol {
    padding: 0;
    margin: 0 0 0 20px;
    & li {
      padding-left: 20px;
    }
  }

  & .quote {
    padding-left: 20px;
    display: block;
  }

  & .center-align {
    text-align: center;
    display: block;
  }

  @media (max-width: 767px) {
    margin: ${props => (props.mobileMargin ? props.mobileMargin : "")};
  }
`;

const TextBox = ({
  text,
  maxWidth,
  margin,
  mobileMargin,
  fontSize,
  lineHeight,
  mobileFontSize,
  mobileLineHeight,
}) => (
  <Text
    dangerouslySetInnerHTML={{ __html: text.html }}
    maxWidth={maxWidth}
    margin={margin}
    mobileMargin={mobileMargin}
    fontSize={fontSize}
    lineHeight={lineHeight}
    mobileFontSize={mobileFontSize}
    mobileLineHeight={mobileLineHeight}
  />
);

export default TextBox;
