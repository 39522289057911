import React from "react";
import styled from "styled-components";

const TitleBox = styled.div`
  width: 100%;

  margin: ${props => (props.margin ? props.margin : "")};
  display: ${props => (props.showDesktop ? props.showDesktop : "none")};

  & h3 {
    font-size: 38px;
    line-height: 20px;
    letter-spacing: 0.1px;

    text-align: center;

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 29px;
    }

    @media (max-width: 500px) {
      font-size: 17px;
      line-height: 22px;
    }
  }

  @media (max-width: 767px) {
    display: ${props => (props.showMobile ? props.showMobile : "none")};

    order: 1;
  }

  @media (max-width: 500px) {
    margin: ${props => (props.mobileMargin ? props.mobileMargin : "40px 0")};
  }
`;

const Title = ({ text, margin, showDesktop, showMobile, mobileMargin }) => (
  <TitleBox
    dangerouslySetInnerHTML={{ __html: text.html }}
    margin={margin}
    showDesktop={showDesktop}
    showMobile={showMobile}
    mobileMargin={mobileMargin}
  />
);

export default Title;
