import React from "react";
import styled from "styled-components";

const Caption = styled.p`
  font-size: 15px;
  max-width: calc(100vh - 100px);

  padding: 10px 0 0 0;
  margin: 0 auto;

  text-align: center;

  margin: 0 auto;
`;

const ImageCaption = ({ text }) => <Caption>{text}</Caption>;

export default ImageCaption;
